.head{
    text-align: center;
    padding-bottom: 20px;
}
.main{
    background-color: #F8F9FD !important;
}
.data{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: white;
    margin: 10px;
    margin-top: 10px;
    padding-top: 45px;
    padding-bottom: 45px;
    box-shadow: 0px 5px 110px 10px rgba(0, 0, 0, 0.05);

}
.data h3{
    padding-top: 16px;
    font-size: 1.5rem;
    font-weight: 600;
}
.data p{
    font-size: 15px;
    padding-top: 10px;
    color: #6c757d!important;
    max-width: 350px;
}
.icon{
color: rgb(255, 153, 0);
font-size: 4.5rem;
}
.hero1{
    display: flex;
    justify-content: center;
}
.hero2{
    display: flex;
    justify-content: center;
}