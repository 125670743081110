.skills .progress {
    height: 60px;
    display: block;
    background: none;
    border-radius: 0;
  }
  .skills .progress .skill {
    padding: 10px 0;
    margin: 0;
    text-transform: uppercase;
    display: block;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #45505B;
  }
  .skills .progress .skill .val {
    float: right;
    font-style: normal;
  }
  .skills .progress-bar-wrap {
    background: #F2F3F5;
    border-radius: 141px;
  }
  .skills .progress-bar {
    width: 1px;
    height: 8px;
    transition: 0.9s;
    border-radius: 141px;
    background-color: rgb(255, 153, 0);
  }