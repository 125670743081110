.test-img{
    width: 70px;
    height: 70px;
    margin-top: 10px;
    border:3px solid  rgb(255, 153, 0);
    border-radius: 50%;
}
.test-bg{
    background-color: #939597!important;
    margin-top: 90px;
}
.test-desigination{
   color: #dee3e4 !important;
   font-size: 0.9rem;
   padding-top: 4px;
}