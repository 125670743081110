footer {
  background-color: white;
  padding-bottom: 10px;
  margin-top: 20px;

}
#footer {
background: #f7f8f9;
  font-size: 14px;
  text-align: center;
  padding-top: 40px;
  
}
.cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.cont > h3{
  font-weight: 500;
  font-size: 36px;
}
.pic {
  margin-bottom: 25px;
}

#footer h3 {
  color: black;
  font-size: 36px;
  font-weight: 700;
  position: relative;
  font-family: "Poppins", sans-serif;
  padding: 0;
  margin: 20px 0 20px 0;
}

#footer p {
  color: black;
  font-size: 15;
  padding: 0;
  margin: 0 0 20px 0;
}
#footer .social-links {
  margin: 0 0 20px 0;
}
.icon-1{
  font-size: 35px;
  color: black;
  margin: 10px;
}
.icon-1:hover{
  color: rgb(255, 153, 0);
  transition: all .5s ease-in-out;
}
/* #footer .social-links a {
  font-size: 18px;
  display: inline-block;
  background: black;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
} */
/* #footer .social-links a:hover {
  background: rgb(255, 153, 0);
  color: black;
  text-decoration: none;
} */
#footer .copyright {
  margin: 0 0 5px 0;
}
#footer .credits {
  font-size: 13px;
}
.designed {
      text-align: center;
      color: #fff;
      i {
        position: relative;
        top: 0px;
        color: orangered;
        animation: heartBeat 1000ms infinite linear;
      }
      a {
        color: orangered;
      }
    }
