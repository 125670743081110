.about {
  background-color: white;
}
.about-text {
  width: 100%;
  max-width: 830px;
  font-size: 21px;
}
.inner-text1 {
  font-size: 9rem;
  margin-top: 0px;
}
.eight {
  display: inline-flex;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background-color: rgb(255, 153, 0);
  align-items: center;
  justify-content: center;
  margin: 0;
  font-weight: 500 ;
  color: #212529;
}

.name-underline{
  border-bottom: 2px solid rgb(255, 153, 0);
  margin: 0 auto;
  margin-bottom: 15px;
  width: 70px;
  font-weight: bold;

}
.exp{
  font-weight: bold;
  color: black !important;
}

@media screen and (min-width: 960px) {
  .about-text-right {
    text-align: center;
  }
  .inner-text2 {
    font-size: 25px;
    margin-top: -10px;
    color: #212529
  }
  .hed-info{
    color: #6c757d !important;
    font-size: 15px;
  }
  .info{
    font-weight: bold;
    font-size: 16px;
    margin-top: -10px;
  }
  .info-container{
    padding-top: 30px;
  }
}
@media screen and (max-width: 959px) and (min-width: 600px) {
  .inner-text1 {
    font-size: 5rem;
    margin-top: 0px;
    text-align: center;
  }
  .eight {
    display: inline-flex;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: rgb(255, 153, 0);
    align-items: center;
    justify-content: center;
    margin: 0;
    font-weight: 500 ;
    color: #212529;
  }
  .inner-text2 {
    font-size: 28px;
    margin-top: 10px;
  }
  .parent{
  grid-template-columns: auto auto;
  }
  .hed-info{
    color: #6c757d !important;
    font-size: 15px;
  }
  .info{
    font-weight: bold;
    font-size: 16px;
    margin-top: -10px;
  }
  .info-container{
    padding-top: 30px;
  }
}
@media screen and (max-width: 599px) {
  .parent{
    grid-template-columns: auto auto;
    justify-content: center;
    }
  .info-wrapper {
    width: 50%; /* Set each info-wrapper to occupy half of the width */
  }
  .about-text{
    text-align: center;
  }
  .about-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .inner-text1 {
    font-size: 5rem;
    margin-top: 0px;
    text-align: center;
    padding-bottom: 10px;

  }
  .eight {
    display: inline-flex;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: rgb(255, 153, 0);
    align-items: center;
    justify-content: center;
    margin: 0;
    font-weight: 500 ;
    color: #212529;
  }
  .info{
    font-weight: bold;
    font-size: 16px;
    margin-top: -10px;
  }
  .inner-text2 {
    font-size: 28px;
    margin-top: 10px;
  }
  .main-name {
    width: 100%;
    font-size: 27px;
  }
  .hed-info{
    color: #6c757d !important;
    font-size: 14px;
  }
  .info{
    font-weight: bold;
    font-size: 15px;
    margin-top: -10px;
    overflow-wrap: break-word;
  }
}
