.topButton {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, .2);
  filter: blur(0.7px);
  color: white;
  cursor: pointer;
  padding: 6px;
  border-radius: 15px;
  font-size: 25px;
  /* box-shadow: 0 5px 15px rgba(0,0,0,.15); */
  transition: all .3s ease-in-out;

}
button.topButton.on:hover {
  background-color: rgb(255, 153, 0);
  transition: all .4s ease-in-out;
  color:black

}
.topButton i {
  display: flex;
}
.topButton.on {
  opacity: 1;
  transition: opacity 0.1s linear;
}
.topButton.off {
  opacity: 0;
  transition: opacity 0.1s linear;
}
