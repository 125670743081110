.faq-accordion .accordion {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    border: none;
  }
  .faq-accordion .accordion__item {
    margin-bottom: 15px;
    border: none;
  }
  .faq-accordion .accordion__item:last-child {
    margin-bottom: 0;
  }
  .faq-img{
    text-align: center;
  }
 
  .faq-accordion .accordion__button {
    display: block;
    /* box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important; */
    background: #ffffff;
    padding: 20px 0px;
    color: #00000066;
    text-decoration: none;
    position: relative;
    display: block;
    font-size: 18px;
    font-weight: 600;
    border-bottom: 1px solid #00000066;
    border-radius: 0%;
    display: flex;
    align-items: center;

  }
  .faq-accordion .accordion__button:focus {
    outline: 0;
  }
  .faq-accordion .accordion__button i {
    position: absolute;
    right: 25px;
    top: 18px;
    font-size: 25px;
    transition: 0.5s;
  }
  .faq-accordion .accordion-title.active i {
    transform: rotate(180deg);
  }
  .faq-accordion .accordion__panel {
    background-color: white;
    position: relative;
    /* padding-bottom: 20px; */
    /* padding-right: 60px; */
    /* padding-left: 25px; */
    /* box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important; */
    
  }
  .faq-accordion .accordion__panel p {
    /* line-height: 1.8; */
    color: #212529;
    border-bottom: 1px solid #00000066;
    padding-bottom: 25px;
    position: relative;
    /* right: 12px; */
    left: 26px;
    top: 0;
    bottom: 0;

    

  }
  .faq-accordion .accordion-content.show {
    display: block;
  }
  .accordion__button[aria-expanded="true"] {
    color: #212529;
    border-bottom: none;
}
.accordion__button [aria-expanded="true"] .faq-accordion .accordion__panel p {
    color: #212529;
    border-bottom: none;
}
  
.IoIosArrowDown {
    transition: transform 0.8s ease;    
  }
  
  /* Rotate the icon when accordion button is expanded */
  .accordion__button[aria-expanded="true"] .IoIosArrowDown {
    transform: rotate(270deg); /* Rotate the icon 180 degrees */
  }

  .text{
    padding-left: 10px;
  }

  @media screen and (max-width: 768px){
    .faq-img img{
      height: 351px;
      width: 341px;
    }
  }
  @media screen and (max-width: 465px){

    .faq-accordion .accordion__panel p {
        /* line-height: 1.8; */
        color: #212529;
        border-bottom: 1px solid #00000066;
        padding-bottom: 25px;
        position: relative;
        /* right: 12px; */
        left: 20px;
        top: 0;
        bottom: 0;
    
      }
  }