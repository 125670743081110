contact .info {
  width: 100%;
}
.thank-you-message{
  text-align: center;
}

.contact .info .i {
  font-size: 16px;
  color: rgb(255, 176, 57);
  float: left;
  width: 44px;
  height: 44px;
  /* background: #EEF7FF; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}
.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  /* color: #45505B; */
}
.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #728394;
}
.contact .info .email,
.contact .info .phone {
  margin-top: 40px;
}
.contact .info .email:hover .i,
.contact .info .address:hover .i,
.contact .info .phone:hover .i {
  background: rgb(255, 176, 57);
  transition: all .6s ease-in-out;
  color: white;
  padding: 5px;
}
.contact .email-form {
  width: 100%;
  /* background: #fff; */
}
.contact .email-form .form-group {
  padding-bottom: 8px;
}
.contact .email-form .error-message {
  display: none;
  color: #fff;
  background: #ED3C0D;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}
.contact .email-form .error-message br+br {
  margin-top: 25px;
}
.contact .email-form .sent-message {
  display: none;
  color: #fff;
  background: #18D26E;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}
.contact .email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}
.contact .email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18D26E;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}
.contact .email-form input,
.contact .email-form textarea {
  border-radius: 4px;
  box-shadow: none;
  font-size: 14px;
}
.contact .email-form input[type=text],
.contact .email-form input[type=email],
.contact .email-form input[type=tel] {
  height: 44px;
}
.contact .email-form textarea {
  padding: 10px 12px;
}
.contact .email-form button[type=submit] {
  background: rgb(255, 176, 57);
  border: 0;
  padding: 10px 35px;
  color: black;
  transition: 0.4s;
  border-radius: 50px;
}
.contact .email-form button[type=submit]:hover {
  background: rgb(255, 153, 0);
}
@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

